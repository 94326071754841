export default {
  productOutOfStock: {
    id: 'product_no_available',
    defaultMessage: 'Oh fiddlesticks! This product is not available',
  },
  productGrade: {
    id: 'product_grade',
    defaultMessage: 'Esthetic condition:',
  },
  crossSellCategory: {
    id: 'funnel_cart_cross_sell_category',
    defaultMessage: 'Cases',
  },
  cartColor: {
    id: 'funnel_cart_color',
    defaultMessage: 'Color :',
  },
  warranty: {
    id: 'funnel_cart_page_warranty',
    defaultMessage: '1 year warranty',
  },
  originalPriceDisplay: {
    id: 'product_page_add_to_cart_original_price_display',
    defaultMessage: '{price} new',
  },
  youSaveDisplay: {
    id: 'product_page_you_save_amount_display',
    defaultMessage: 'Save {reduction}',
  },
  shippingPromise: {
    id: 'cart_shipping_promise',
    defaultMessage: 'Get it by {earliest} - {latest} • Free',
  },
  shippingPromiseExpress: {
    id: 'cart_express_shipping_promise',
    defaultMessage: 'Express by {latest} • from {amount}',
  },
  productRemove: {
    id: 'product_remove',
    defaultMessage: 'Remove',
  },
  productGradeWithNewBattery: {
    id: 'product_grade_with_new_battery',
    defaultMessage: '{grade} with new battery',
  },
  bundlingDiscount: {
    id: 'funnel_cart_bundle_discount',
    defaultMessage: 'Bundling saved you {price}',
  },
}
