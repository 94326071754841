export default {
  title: {
    id: 'battery_replacement_modal_title_offer',
    defaultMessage: 'Add {offer}',
  },
  subtitle: {
    id: 'battery_replacement_modal_subtitle',
    defaultMessage: 'Battery replacement',
  },
  accept: {
    id: 'battery_replacement_modal_accept',
    defaultMessage: 'Add battery replacement',
  },
  ignore: {
    id: 'battery_replacement_modal_ignore',
    defaultMessage: 'Continue without replacement',
  },
  close: {
    id: 'battery_replacement_modal_close_modal',
    defaultMessage: 'Close',
  },
}
