export default {
  close: {
    id: 'battery_cheapest_insurance_modal_close_modal',
    defaultMessage: 'Close',
  },
  title: {
    id: 'battery_cheapest_insurance_modal_subtitle',
    defaultMessage: 'Get fast and easy repairs',
  },
  accept: {
    id: 'battery_cheapest_insurance_modal_accept',
    defaultMessage: 'Add protection plan',
  },
  ignore: {
    id: 'battery_cheapest_insurance_modal_ignore',
    defaultMessage: 'Continue without protection',
  },
}
