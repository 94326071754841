<template>
  <RevDialog
    :closeButtonLabel="i18n(translations.close)"
    :name="MODAL_NAMES.CATCH_UP_INSURANCE_OFFER"
    :title="i18n(translations.title)"
  >
    <template #body>
      <div class="space-y-16">
        <div class="relative">
          <div class="flex justify-center">
            <RevIllustration
              alt=""
              class="z-10"
              :height="196"
              src="/img/checkout/insuranceModal.svg"
              :width="220"
            />
          </div>
          <div
            class="rounded-lg absolute top-[22px] flex h-[123px] w-full bg-[#6E83F0]"
          />
        </div>

        <CatchUpModalOffer :insurance-offer="insuranceOffer">
          <template #title>
            <h3>{{ insuranceOffer.title }}</h3>
          </template>
          <template #subtitle>
            <h4>
              {{ deviceName }}
            </h4>
          </template>
        </CatchUpModalOffer>
      </div>
    </template>

    <template #footer>
      <div class="space-y-12">
        <RevButton full-width="always" variant="primary" @click="handleAccept">
          {{ i18n(translations.accept) }}
        </RevButton>
        <RevButton
          full-width="always"
          variant="secondary"
          @click="handleDecline"
        >
          {{ i18n(translations.ignore) }}
        </RevButton>
      </div>
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import { type CheckoutInsuranceOffer } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { RevIllustration } from '@ds/components/Illustration'

import { MODAL_NAMES } from '~/scopes/checkout/config/constants'

import CatchUpModalOffer from '../CatchUpModalOffer/CatchUpModalOffer.vue'

import translations from './CheapestInsuranceOfferModal.translations'

const tracking = useTracking()

defineProps<{
  insuranceOffer: CheckoutInsuranceOffer
  deviceName: string
}>()

const emit = defineEmits(['select-offer', 'decline'])

function handleAccept() {
  tracking.trackClick({
    name: 'add_protection_plan',
    zone: 'cart_insurance_modal_reminder',
  })

  emit('select-offer')
}

function handleDecline() {
  tracking.trackClick({
    name: 'continue_without_protection_plan',
    zone: 'cart_insurance_modal_reminder',
  })

  emit('decline')
}

const i18n = useI18n()
</script>
