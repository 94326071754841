<template>
  <RevDialog
    :closeButtonLabel="i18n(translations.close)"
    :name="MODAL_NAMES.CATCH_UP_INSURANCE_OFFER"
    :title="i18n(translations.title, { offer: insuranceOffer.title })"
  >
    <template #body>
      <div class="space-y-16">
        <BatteryReplacementIllustration />

        <CatchUpModalOffer :insurance-offer="insuranceOffer">
          <template #title>
            <h3>{{ i18n(translations.subtitle) }}</h3>
          </template>
          <template #subtitle>
            <h4>{{ deviceName }}</h4>
          </template>
        </CatchUpModalOffer>
      </div>
    </template>

    <template #footer>
      <div class="space-y-12">
        <RevButton
          full-width="always"
          variant="primary"
          @click="emit('select-offer')"
        >
          {{ i18n(translations.accept) }}
        </RevButton>
        <RevButton
          full-width="always"
          variant="secondary"
          @click="emit('decline')"
        >
          {{ i18n(translations.ignore) }}
        </RevButton>
      </div>
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import { type CheckoutInsuranceOffer } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'

import { MODAL_NAMES } from '~/scopes/checkout/config/constants'
import BatteryReplacementIllustration from '~/scopes/checkout/pages/Cart/components/BatteryReplacementIllustration/BatteryReplacementIllustration.vue'

import CatchUpModalOffer from '../CatchUpModalOffer/CatchUpModalOffer.vue'

import translations from './BatteryReplacementModal.translations'

defineProps<{
  insuranceOffer: CheckoutInsuranceOffer
  deviceName: string
}>()

const emit = defineEmits(['select-offer', 'decline'])

const i18n = useI18n()
</script>
