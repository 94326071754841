<template>
  <div>
    <RevStickyBar class="md:hidden" :visible="true">
      <div class="flex flex-row items-end">
        <div
          class="mr-12 flex min-w-0 flex-col"
          :class="
            withDiscountedSwapExperiment ? ['flex-1', 'truncate'] : ['flex-0']
          "
        >
          <span
            :class="
              withDiscountedSwapExperiment ? ['caption-bold'] : ['body-1']
            "
          >
            {{ i18n(translations.stickyButtonTotal) }}
          </span>
          <div>
            <span
              :class="
                withDiscountedSwapExperiment ? ['heading-2'] : ['body-1-bold']
              "
            >
              {{ totalPriceFormatted }}</span
            >
            <span
              v-if="
                withDiscountedSwapExperiment && (withMobilePlan || withSwap)
              "
              class="caption-bold"
            >
              {{ i18n(translations.stickyPriceDueToday) }}
            </span>
          </div>
          <span v-if="withDiscountedSwapExperiment && withSwap" class="body-2">
            {{
              i18n(translations.priceAfterTradeIn, {
                price: i18n.price(
                  // Can't be undefined since we are checking if there is swap present
                  cartStore.totalPriceAfterBuyback!,
                ),
              })
            }}
          </span>
        </div>
        <RevButton
          :class="withDiscountedSwapExperiment && 'flex-1'"
          :data-qa="`go-to-next-${placement}`"
          :disabled="disabled"
          full-width="responsive"
          :loading="loading"
          variant="primary"
          @click="$emit('next')"
        >
          {{ i18n(translations.button) }}
        </RevButton>
      </div>
    </RevStickyBar>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButton } from '@ds/components/Button'
import { RevStickyBar } from '@ds/components/StickyBar'

import { useCartStore } from '../stores/cartStore'
import { useDiscountStore } from '../stores/discountStore'

import translations from './CheckoutNext.translations'

withDefaults(
  defineProps<{
    disabled?: boolean
    loading?: boolean
    withSwap?: boolean
    placement?: string
  }>(),
  {
    disabled: false,
    loading: false,
    withSwap: false,
    placement: '',
  },
)

defineEmits(['next'])

const marketPlace = useMarketplace()
const cartStore = useCartStore()
const discountStore = useDiscountStore()
const i18n = useI18n()

const experiments = useExperiments()

const totalPrice = computed(() =>
  discountStore.isApplied ? discountStore.getDeductedPrice : cartStore.price,
)

const totalPriceFormatted = computed(
  () =>
    `${i18n.price(totalPrice.value)} ${
      marketPlace.features.vatIncluded ? i18n(translations.tvaIncluded) : ''
    }`,
)

const withMobilePlan = computed(() =>
  cartStore.items.some(
    (item) => item.mobilePlan && item.mobilePlan.selectedOffer,
  ),
)

const withDiscountedSwapExperiment = computed(
  () => experiments['experiment.ppDiscountedSwap'] === 'withDiscountedSwap',
)
</script>
