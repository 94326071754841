<template>
  <Bundle v-if="isBouyguesOffer(mobilePlanOffer)" class="my-32 md:my-56">
    <BouyguesMobilePlanBundle
      data-qa="mobile-plan"
      data-test="bouygues-mobile-plan"
      :offer="mobilePlanOffer"
      @remove="handleRemoveMobilePlan"
    />
  </Bundle>
</template>

<script setup lang="ts">
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { deleteService } from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

import { isBouyguesOffer } from '~/scopes/checkout/utils/isBouyguesOffer'

import Bundle from '../Bundle/Bundle.vue'

import translations from './MobilePlanBundle.translations'
import BouyguesMobilePlanBundle from './components/BouyguesMobilePlanBundle/BouyguesMobilePlanBundle.vue'

defineProps<{
  mobilePlanOffer: MobilePlanOffer
}>()

const i18n = useI18n()
const { openErrorToast, openSuccessToast } = useTheToast()
const emit = defineEmits<{
  (e: 'remove'): void
}>()

async function handleRemoveMobilePlan(offerId: string) {
  try {
    await $httpFetch(deleteService, { body: { offerId } })

    openSuccessToast({
      title: i18n(translations.mobilePlanRemovalSuccessfulTitle),
      content: i18n(translations.mobilePlanRemovalSuccessfulContent),
    })

    emit('remove')
  } catch {
    openErrorToast()
  }
}
</script>
