<template>
  <div class="flex items-end justify-between md:items-start">
    <div class="space-y-8">
      <div>
        <div class="body-1-bold flex gap-8">
          <BouyguesLogo />

          <p>{{ offer.label }}</p>
        </div>

        <RevLink
          :tracking="{
            zone: 'bouygues_plan_cart',
            name: 'see_offers_detail',
          }"
          @click="openModal(MODAL_NAMES.BOUYGUES)"
        >
          <span class="text-static-default-low body-2-link">
            <EnrollmentPeriod
              :enrollment-period="offer.enrollmentPeriod"
              :enrollment-translation="translations.enrollmentPeriod"
            />
          </span>
        </RevLink>

        <BouyguesOffersDrawer :eligible-offers-ids="[offer.id]" />
      </div>

      <div v-if="priceOption">
        <div
          v-if="priceOption.prices[0]"
          class="flex flex-col md:flex-row md:items-end md:gap-4"
        >
          <p class="body-1-bold">
            {{
              i18n(translations.firstPeriodPrice, {
                price: i18n.price(priceOption.prices[0].price),
              })
            }}
          </p>

          <p v-if="priceOption.prices[0].monthsDuration" class="body-2">
            {{
              i18n(translations.firstPeriodDuration, {
                duration: getFormattedDuration(
                  priceOption.prices[0].monthsDuration,
                ),
              })
            }}
          </p>
        </div>

        <p v-if="priceOption.prices[1]" class="text-static-default-low body-2">
          {{
            i18n(translations.secondPeriodPrice, {
              price: i18n.price(priceOption.prices[1].price),
            })
          }}
        </p>
      </div>

      <p class="text-static-default-low body-2">
        {{
          i18n(translations.shippingDuration, {
            durationInBusinessDays: 5,
          })
        }}
      </p>
    </div>

    <RevButtonTiny
      :tracking="{
        zone: 'cart',
        name: 'remove_bouygues_mobile_plan',
      }"
      variant="secondaryDestructive"
      @click="emits('remove', offer.id)"
    >
      {{ i18n(translations.removeButton) }}
    </RevButtonTiny>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'

import BouyguesLogo from '~/components/BouyguesLogo/BouyguesLogo.vue'
import { useFormatMonthsYearsDuration } from '~/composables/useFormatMonthsYearsDuration'
import EnrollmentPeriod from '~/scopes/checkout/components/EnrollmentPeriod/EnrollmentPeriod.vue'
import BouyguesOffersDrawer from '~/scopes/product/components/CustomizationFunnel/components/BouyguesOffersDrawer/BouyguesOffersDrawer.vue'
import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './BouyguesMobilePlanBundle.translations'

const i18n = useI18n()

const props = defineProps<{ offer: MobilePlanOffer }>()
const emits = defineEmits(['remove'])

const { getFormattedDuration } = useFormatMonthsYearsDuration()

const priceOption = computed(() => {
  return props.offer.priceOptions.find(
    (option) => option.option === 'KEEP_PHONE_NUMBER',
  )
})
</script>
