export default {
  mobilePlanRemovalSuccessfulTitle: {
    id: 'funnel_cart_mobile_plan_removal_successful_title',
    defaultMessage: 'Mobile plan removed',
  },
  mobilePlanRemovalSuccessfulContent: {
    id: 'funnel_cart_mobile_plan_removal_successful_content',
    defaultMessage: 'The price of your order has been refreshed',
  },
}
