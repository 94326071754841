<template>
  <div>
    <InsurancesOptions
      ref="insurancesOptions"
      can-be-deselected
      data-test="insurances"
      :listing-id="item.listingId"
      :offers="item.insuranceOffers"
      :product-name="item.model"
      :selected-offer
      show-selected
      tracking-zone="cart"
      @update-compliancy="
        (offer) => handleUpdateCompliancy(offer as CheckoutInsuranceOffer)
      "
      @update-selected-option="
        (offer) => updateSelectedOption(offer as CheckoutInsuranceOffer)
      "
    />
    <p
      v-if="hasCompliancyError && shouldDisplayError"
      class="text-static-danger-hi mt-3"
    >
      {{ i18n(translations.trustpackCGVError) }}
    </p>

    <template v-if="withCatchUpModal">
      <!-- To be removed (B2CS-638)  -->
      <LegacyCatchUpModal
        :id="item.listingId"
        class="z-10"
        :model="item.model"
        :offers="item.insuranceOffers"
        @continue="handleLegacyCatchupModalClose"
        @go-to="handleLegacyCatchupModalGoTo"
      />

      <CatchUpModal
        :device-name="item.model"
        :insurance-offers="item.insuranceOffers"
        @decline="handleDeclineCatchUpModal"
        @select-insurance-offer="handleSelectCatchUpModal"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import {
  postAcceptAgreement,
  postUpdateInsuranceOffer,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import type {
  CartItem,
  CheckoutInsuranceOffer,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { closeModal } from '@ds/components/ModalBase'

import InsurancesOptions from '~/scopes/checkout/components/InsurancesOptions/InsurancesOptions.vue'
import useHandleUnauthorizedUser from '~/scopes/checkout/composables/useHandleUnauthorizedUser'
import { hasOfferCompliancyError } from '~/scopes/checkout/pages/Cart/utils/insuranceOffers'
import { useCartStore } from '~/scopes/checkout/stores/cartStore'

import translations from './Insurances.translations'
import CatchUpModal from './components/CatchUpModal/CatchUpModal.vue'
import LegacyCatchUpModal from './components/CatchUpModal/components/InsuranceCatchupModal/InsuranceCatchupModal.vue'

const props = defineProps<{
  shouldDisplayError: boolean
  withCatchUpModal: boolean
  item: CartItem
}>()

const emit = defineEmits<{
  update: [updatedInsuranceOffers: CheckoutInsuranceOffer[]]
  'ignore-catchup-modal': []
}>()

const experiments = useExperiments()
const i18n = useI18n()
const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

const selectedOffer = ref<CheckoutInsuranceOffer>(
  props.item.insuranceOffers.find((offer) => offer.selected) ||
    props.item.insuranceOffers.find((offer) => offer.defaultOffer) ||
    props.item.insuranceOffers[0],
)

const insurancesOptions = ref<{ $el: HTMLElement } | undefined>()
const cartStore = useCartStore()

const hasCompliancyError = computed(() =>
  props.item.insuranceOffers.some(hasOfferCompliancyError),
)

const isMonthlyInsuranceEnabled = computed(
  () =>
    experiments['experiment.insuranceMonthlySubscription'] ===
    'insuranceMonthlyEnabled',
)

function scrollToInsuranceOptions() {
  if (!insurancesOptions.value || !insurancesOptions.value.$el) return

  insurancesOptions.value.$el.scrollIntoView({
    block: 'center',
    behavior: 'smooth',
  })
}

const handleUpdateCompliancy = async (
  updatedInsurance: CheckoutInsuranceOffer,
) => {
  selectedOffer.value = updatedInsurance

  try {
    await $httpFetch(postAcceptAgreement, {
      body: {
        listingId: props.item.listingId,
        insuranceOfferId: updatedInsurance.id,
      },
      queryParams: {
        monthlyInsuranceSupported: isMonthlyInsuranceEnabled.value,
      },
    })

    const updatedInsuranceOffers = props.item.insuranceOffers.reduce<
      CheckoutInsuranceOffer[]
    >((offers, offer) => {
      if (offer.id === updatedInsurance.id) {
        return [...offers, updatedInsurance]
      }

      return [...offers, offer]
    }, [])

    emit('update', updatedInsuranceOffers)
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating terms and conditions',
    )
  }
}

const updateSelectedOption = async (
  updatedInsurance: CheckoutInsuranceOffer,
) => {
  try {
    selectedOffer.value = updatedInsurance

    await $httpFetch(postUpdateInsuranceOffer, {
      body: {
        listingId: props.item.listingId,
        insuranceOfferId: updatedInsurance.id,
      },
      queryParams: {
        monthlyInsuranceSupported: isMonthlyInsuranceEnabled.value,
      },
    })

    const updatedInsuranceOffers = props.item.insuranceOffers.reduce<
      CheckoutInsuranceOffer[]
    >((offers, offer) => {
      if (offer.id === updatedInsurance.id) {
        return [...offers, updatedInsurance]
      }

      return [...offers, { ...offer, selected: false }]
    }, [])

    emit('update', updatedInsuranceOffers)
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating insurance offer',
    )
  }
}

const handleLegacyCatchupModalClose = () => {
  cartStore.skipCatchupModal()
  emit('ignore-catchup-modal')
}

function handleSelectCatchUpModal(insuranceOffer: CheckoutInsuranceOffer) {
  closeModal()
  cartStore.skipCatchupModal()

  updateSelectedOption({ ...insuranceOffer, selected: true })
}

const handleDeclineCatchUpModal = () => {
  closeModal()
  cartStore.skipCatchupModal()
  emit('ignore-catchup-modal')
}

function handleLegacyCatchupModalGoTo() {
  closeModal()
  scrollToInsuranceOptions()
}
</script>
