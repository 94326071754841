import type { CheckoutInsuranceOffer } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'

export function hasOfferCompliancyError(
  insuranceOffer: CheckoutInsuranceOffer,
) {
  return (
    insuranceOffer.selected &&
    insuranceOffer.compliancy.isUserAgreementRequired &&
    !insuranceOffer.compliancy.isUserAgreementAccepted
  )
}

function isDefaultOfferSelected(insuranceOffer: CheckoutInsuranceOffer) {
  return insuranceOffer.defaultOffer && insuranceOffer.selected
}

export function hasInsuranceSelected(
  insuranceOffers: CheckoutInsuranceOffer[],
) {
  return !insuranceOffers.some(isDefaultOfferSelected)
}
