<template>
  <RevCard class="p-24 lg:p-32">
    <div class="flex flex-col justify-between">
      <div class="mb-24 flex items-center gap-16">
        <div class="border-static-default-low rounded-md shrink-0 border p-8">
          <IconSwap size="24" />
        </div>
        <div class="w-full">
          <div class="body-1-bold flex items-center gap-8">
            {{
              i18n(translations.buybackSwapSummaryTradeInValue, {
                price: i18n.price(price),
              })
            }}
            <RevButtonBase class="shrink-0 md:hidden" @click="openSummaryModal">
              <IconInfo size="16" />
            </RevButtonBase>
            <RevButtonTiny
              class="ml-auto hidden md:block"
              data-qa="swap-summary-delete"
              data-test="swap-summary-delete"
              variant="secondaryDestructive"
              @click="handleDelete"
            >
              {{ i18n(translations.buybackSwapSummaryTradeInRemove) }}
            </RevButtonTiny>
          </div>
          <div class="body-2">{{ title }}</div>
        </div>
      </div>
      <RevDivider class="mb-24" />
      <div
        class="mb-24 grid grid-cols-1 gap-8 md:mb-0 md:grid-cols-2 md:gap-12"
      >
        <div
          v-for="{ icon, label } in tradeInDetails"
          :key="label"
          class="flex items-center gap-12"
        >
          <component :is="icon" class="shrink-0" size="24" />
          <div class="text-static-default-low body-2">{{ label }}</div>
        </div>
      </div>
      <RevButtonTiny
        class="ml-auto md:hidden"
        variant="secondaryDestructive"
        @click="handleDelete"
      >
        {{ i18n(translations.buybackSwapSummaryTradeInRemove) }}
      </RevButtonTiny>
      <RevButtonBase
        class="body-2-link ml-auto hidden md:block"
        @click="openSummaryModal"
      >
        {{ i18n(translations.buybackSwapSummaryTradeInLearnMore) }}
      </RevButtonBase>
      <RevModal
        closable
        :name="MODAL_NAMES.SWAP_SUMMARY"
        :title="i18n(translations.buybackSwapSummaryTradeSummaryModalTitle)"
        variant="panel"
      >
        <template #body>
          <div>
            <div class="heading-3 mb-12">
              {{
                i18n(translations.buybackSwapSummaryTradeSummaryModalBodyTitle)
              }}
            </div>
            <div class="mb-52">
              <FormattedMessage
                class="body-1"
                :definition="
                  translations.buybackSwapSummaryTradeSummaryModalBodyDescription
                "
              >
                <template #tradein>
                  <span class="body-1-bold">
                    {{ i18n.price(price) }}</span
                  ></template
                >
                <template v-if="totalPriceAfterBuyback" #totalprice>
                  {{ i18n.price(totalPriceAfterBuyback) }}
                </template>
              </FormattedMessage>
            </div>
            <RevTextList variant="ordered">
              <RevTextListItem
                v-for="{ label, title: titleDetail } in tradeInDetailsModal"
                :key="label"
                class="mb-24 flex items-center gap-24"
              >
                <div class="flex flex-col">
                  <div class="body-1-bold mb-8">{{ titleDetail }}</div>
                  <div class="body-2">{{ label }}</div>
                </div>
              </RevTextListItem>
            </RevTextList>
          </div>
        </template>
      </RevModal>
    </div>
  </RevCard>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { Price } from '@backmarket/http-api/src/api-models/Price'
import { BUYBACK_SHIPPING_MODE } from '@backmarket/http-api/src/api-specs-buyback/constants'
import type { GetOfferResponseLegacy } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferLegacy'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'
import { RevModal } from '@ds/components/Modal'
import { openModal } from '@ds/components/ModalBase'
import { RevTextList } from '@ds/components/TextList'
import { RevTextListItem } from '@ds/components/TextListItem'
import { IconDownload } from '@ds/icons/IconDownload'
import { IconInfo } from '@ds/icons/IconInfo'
import { IconMoney } from '@ds/icons/IconMoney'
import { IconPackage } from '@ds/icons/IconPackage'
import { IconSwap } from '@ds/icons/IconSwap'
import { IconTruckExpress } from '@ds/icons/IconTruckExpress'

import { MODAL_NAMES } from '~/scopes/buyback/constants'

import translations from './SwapSummary.translations'

const i18n = useI18n()
const { trackClick } = useTracking()

export interface SwapSummaryProps {
  title: string
  price: Price | number
  shippingMode: GetOfferResponseLegacy['defaultShippingModeId'] | undefined
  totalPriceAfterBuyback?: Price
}

const { shippingMode } = withDefaults(defineProps<SwapSummaryProps>(), {
  totalPriceAfterBuyback: undefined,
})

const emit = defineEmits<{
  (event: 'delete-swap'): void
}>()

function handleDelete() {
  emit('delete-swap')
}

const tradeInDetailsShipping = computed(() => {
  if (shippingMode && shippingMode === BUYBACK_SHIPPING_MODE.NEW_KIT) {
    return {
      icon: IconPackage,
      label: i18n(translations.buybackSwapSummaryTradeMailing),
    }
  }
  if (shippingMode && shippingMode === BUYBACK_SHIPPING_MODE.QR_CODE) {
    return {
      icon: IconPackage,
      label: i18n(translations.buybackSwapSummaryTradeMailingQrCode),
    }
  }

  return {
    icon: IconDownload,
    label: i18n(translations.buybackSwapSummaryTradeNoMailing),
  }
})

const tradeInDetails = computed(() => {
  return [
    tradeInDetailsShipping.value,
    {
      icon: IconTruckExpress,
      label: i18n(translations.buybackSwapSummaryTradeInShipping),
    },
    {
      icon: IconMoney,
      label: i18n(translations.buybackSwapSummaryTradeInMoney),
    },
  ]
})

const tradeInDetailsModalShipping = computed(() => {
  if (shippingMode && shippingMode === BUYBACK_SHIPPING_MODE.NEW_KIT) {
    return {
      title: i18n(
        translations.buybackSwapSummaryTradeSummaryModalDetailsBoxTitle,
      ),
      label: i18n(
        translations.buybackSwapSummaryTradeSummaryModalDetailsBoxLabel,
      ),
    }
  }
  if (shippingMode && shippingMode === BUYBACK_SHIPPING_MODE.QR_CODE) {
    return {
      title: i18n(
        translations.buybackSwapSummaryTradeSummaryModalDetailsQrCodeTitle,
      ),
      label: i18n(
        translations.buybackSwapSummaryTradeSummaryModalDetailsQrCodeLabel,
      ),
    }
  }

  return {
    title: i18n(
      translations.buybackSwapSummaryTradeSummaryModalDetailsNoBoxTitle,
    ),
    label: i18n(
      translations.buybackSwapSummaryTradeSummaryModalDetailsNoBoxLabel,
    ),
  }
})

const tradeInDetailsModal = computed(() => {
  return [
    tradeInDetailsModalShipping.value,
    {
      title: i18n(
        translations.buybackSwapSummaryTradeSummaryModalDetailsShippingTitle,
      ),
      label: i18n(
        translations.buybackSwapSummaryTradeSummaryModalDetailsShippingLabel,
      ),
    },
    {
      title: i18n(
        translations.buybackSwapSummaryTradeSummaryModalDetailsMoneyTitle,
      ),
      label: i18n(
        translations.buybackSwapSummaryTradeSummaryModalDetailsMoneyLabel,
      ),
    },
  ]
})

const openSummaryModal = () => {
  trackClick({
    zone: 'cart_swapBlock',
    name: 'swap_cart_modal',
  })
  openModal(MODAL_NAMES.SWAP_SUMMARY)
}
</script>
