<template>
  <BatteryReplacementModal
    v-if="batteryReplacementInsuranceOffer"
    :device-name="deviceName"
    :insurance-offer="batteryReplacementInsuranceOffer"
    @decline="emit('decline')"
    @select-offer="
      emit('select-insurance-offer', batteryReplacementInsuranceOffer)
    "
  />

  <CheapestInsuranceOfferModal
    v-if="!batteryReplacementInsuranceOffer && cheapestInsuranceOffer"
    :device-name="deviceName"
    :insurance-offer="cheapestInsuranceOffer"
    @decline="emit('decline')"
    @select-offer="emit('select-insurance-offer', cheapestInsuranceOffer)"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { CheckoutInsuranceOffer } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'

import BatteryReplacementModal from './components/BatteryReplacementModal/BatteryReplacementModal.vue'
import CheapestInsuranceOfferModal from './components/CheapestInsuranceOfferModal/CheapestInsuranceOfferModal.vue'

const props = defineProps<{
  deviceName: string
  insuranceOffers: CheckoutInsuranceOffer[]
}>()

const emit = defineEmits<{
  (e: 'select-insurance-offer', insuranceOffer: CheckoutInsuranceOffer): void
  (e: 'decline'): void
}>()

const cheapestInsuranceOffer = computed(() => {
  return props.insuranceOffers
    .filter((insuranceOffer) => !insuranceOffer.defaultOffer)
    .reduce<CheckoutInsuranceOffer | null>((cheapestOffer, insuranceOffer) => {
      if (!cheapestOffer) return insuranceOffer

      if (
        parseFloat(insuranceOffer.price.amount) <
        parseFloat(cheapestOffer.price.amount)
      )
        return insuranceOffer

      return cheapestOffer
    }, null)
})

const batteryReplacementInsuranceOffer = computed(() => {
  return props.insuranceOffers.find((offer) =>
    offer.coverageKinds.includes('BATTERY_REPLACEMENT'),
  )
})
</script>
