<template>
  <RevModal :name="name" variant="confirmation">
    <template #body>
      <div class="flex flex-col items-center">
        <RevIllustration
          alt=""
          :height="159"
          src="/img/checkout/insuranceModal.svg"
          :width="159"
        />

        <FormattedMessage
          class="body-1 mt-24 text-center"
          :definition="translations.description"
        >
          <template #deviceName>
            <span class="body-1-bold">
              {{ model }}
            </span>
          </template>
          <template #damage>
            <span class="body-1-bold"> {{ i18n(translations.damage) }} </span>
          </template>
          <template #theft>
            <span class="body-1-bold"> {{ i18n(translations.theft) }} </span>
          </template>
          <template #price>
            <span class="body-1-bold">{{ price }} </span>
          </template>
        </FormattedMessage>
      </div>
    </template>
    <template #footer>
      <div class="mt-24 flex flex-col gap-16">
        <RevButton variant="secondary" @click="handleGoTo">
          {{ i18n(translations.add) }}
        </RevButton>

        <RevButton
          data-qa="continue-without-insurance"
          variant="primary"
          @click="handleCloseModal"
        >
          {{ i18n(translations.continue) }}
        </RevButton>
      </div>
    </template>
  </RevModal>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevModal } from '@ds/components/Modal'
import { closeModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/checkout/config/constants'
import { useCartStore } from '~/scopes/checkout/stores/cartStore'
import type { CartInsuranceOffer } from '~/scopes/checkout/types/CartItem'

import translations from './InsuranceCatchupModal.translations'

const props = withDefaults(
  defineProps<{
    id: string
    offers?: CartInsuranceOffer[] | null
    model?: string
  }>(),
  {
    model: '',
    offers: null,
  },
)

const emit = defineEmits(['continue', 'go-to'])

const cartStore = useCartStore()
const i18n = useI18n()
const tracking = useTracking()

const name = computed(() => MODAL_NAMES.LEGACY_CART_INSURANCE_OFFER_CATCHUP)

const insurance = computed(
  () => props.offers && props.offers[props.offers.length - 1],
)

const price = computed(() =>
  insurance.value ? i18n.price(insurance.value.price) : '',
)

function handleCloseModal() {
  cartStore.skipCatchupModal()
  tracking.trackClick({
    name: 'continue_with_no_coverage',
    zone: 'cart_insurance_modal_reminder',
  })
  emit('continue')
}

function handleGoTo() {
  cartStore.skipCatchupModal()
  tracking.trackClick({
    name: 'go_to_cart_to_add_coverage',
    zone: 'cart_insurance_modal_reminder',
  })
  emit('go-to', { id: props.id })
  closeModal()
}
</script>
