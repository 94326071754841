<template>
  <div class="space-y-16">
    <div>
      <div class="heading-3 flex justify-between">
        <slot name="title" />
        <div v-if="insuranceOffer.isMonthly">
          {{
            i18n(translations.monthlyPrice, {
              price: i18n.price(insuranceOffer.price),
            })
          }}
        </div>
        <div v-else>{{ i18n.price(insuranceOffer.price) }}</div>
      </div>
      <div class="body-2">
        <slot name="subtitle" />
      </div>
    </div>

    <div class="space-y-8">
      <div
        v-for="benefit in insuranceOffer.benefits.long"
        :key="benefit"
        class="flex gap-4"
      >
        <IconCheckInCircle class="text-static-success-hi mt-2 h-16 w-16" />
        <div class="text-static-default-low body-2">{{ benefit }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type CheckoutInsuranceOffer } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import translations from './CatchUpModalOffer.translations'

defineProps<{
  insuranceOffer: CheckoutInsuranceOffer
}>()

const i18n = useI18n()
</script>
