<template>
  <RevContentBlock
    v-if="hasNoItems"
    :button-label="i18n(translations.noItemsButton)"
    data-qa="empty-cart-block"
    :image-props="
      {
        alt: '',
        height: 463,
        src: '/img/checkout/emptyBasket.svg',
        style: {
          height: 'auto',
          margin: '0 auto',
          width: 'auto',
        },
        width: 438,
      } as any
    "
    :surtitle="i18n(translations.noItemsSurtitle)"
    :title="i18n(translations.noItemsTitle)"
    :to="resolveLocalizedRoute({ name: HOME })"
  >
    <p class="body-1">
      {{ i18n(translations.noItemsDescription) }}
    </p>
  </RevContentBlock>

  <ClientOnly>
    <div v-if="cartStore.hasAvailableItems">
      <Teleport to="#checkout-side-cart-submit">
        <RevButton
          class="mt-24"
          data-qa="go-to-next-side"
          :disabled="isNextButtonDisabled"
          full-width="always"
          :loading="isNextButtonLoading"
          variant="primary"
          @click="() => nextStep()"
        >
          {{ i18n(translations.buttonCheckout) }}
        </RevButton>
        <div class="caption mt-4 flex items-center justify-center">
          <IconLockLocked class="mr-4 h-16 w-16 self-baseline" />

          {{ i18n(translations.securePayment) }}
        </div>
      </Teleport>
    </div>
  </ClientOnly>

  <div v-if="hasItems">
    <CheckoutNext
      :disabled="isNextButtonDisabled"
      :loading="isNextButtonLoading"
      placement="top"
      :with-swap="swapStore.hasOffer"
      @next="nextStep"
    />

    <h1 class="heading-3 mb-12 mt-24 md:mb-24 md:mt-0">
      {{ i18n(translations.mainTitle) }}
    </h1>

    <CartItemCard
      v-for="item in cartStore.items"
      :key="item.listingId"
      class="mb-12 md:mb-56"
      :is-first-item-with-unselected-insurance="
        firstItemWithUnselectedInsurance?.listingId === item.listingId
      "
      :item="item"
      :quantity="desiredQuantityPerItem[item.listingId] || 1"
      :should-display-compliancy-error
      @ignore-catchup-modal="nextStep"
      @remove="handleDeleteButtonClick(item)"
      @update="handleUpdateCartItem"
      @update-quantity="
        (quantity) => handleQuantitySelectorChange(item, quantity)
      "
    >
      <div v-if="!hasDeliveryPromise" class="space-y-56">
        <div v-for="option in item.options" :key="option.type">
          <span class="body-1 mb-24 flex">
            {{ option.title }}
          </span>

          <ShippingOptions
            :listing-id="item.listingId"
            :option="option"
            :show-collection-point-error="hasCollectionPointError"
            @select="selectOption"
          />
        </div>
      </div>
    </CartItemCard>

    <SwapSummary
      v-if="swapStore.offer"
      class="mb-56 mt-32"
      data-qa="swap-summary"
      data-test="swap-summary"
      :details="swapStore.offer.diagnosticSummary"
      :price="swapStore.offer.price"
      :shipping-mode="swapStore.offer.defaultShippingModeId"
      :title="swapStore.offer.title"
      :total-price-after-buyback="cartStore.totalPriceAfterBuyback"
      @delete-swap="handleDeleteSwap"
    />

    <RevCard v-if="swapStore.hasAdvertisement" class="mb-56 mt-32 md:hidden">
      <SwapBlock
        compact
        :grade-id="0"
        :modal-name="BUYBACK_MODAL_NAMES.SWAP_CART"
        :model="swapAdvertisement.model"
        :price="swapAdvertisement.price"
        product-category=""
        shipping=""
        :swap-offer="swapAdvertisement as Estimation"
        swap-redirection=""
        :swap-status="SWAP_SUCCESS"
        tracking-label="swap_from_cart"
        @confirmation="handleSwapConfirmation"
      />
    </RevCard>

    <div>
      <RevDivider v-if="isCrossSellVisible" class="my-40 md:my-56" />

      <RecommendationCarousel
        :options="{
          withCrossedPrice: true,
          withStartingFrom: false,
          withGrade: true,
          withAddToCart: true,
        }"
        :recommendation-query="{
          category: 'crossSellWeb',
          limit: 12,
          personalisation: true,
          scope: 'cart',
          scopeId: recommendationIds,
        }"
        :title="i18n(translations.titleCrossSell)"
        :trackingData="{
          list: 'cross sell',
        }"
        @loaded="handleCrossSellLoaded"
        @refresh="handleCrossSellRefresh"
      />

      <RevDivider v-if="isCrossSellVisible" class="my-40 md:my-56" />
    </div>

    <ReassuranceItems>
      <BouyguesReassuranceItems
        v-if="cartStore.bouyguesMobilePlan"
        :benefits="cartStore.bouyguesMobilePlan.benefits"
      />
    </ReassuranceItems>

    <CheckoutNext
      :disabled="isNextButtonDisabled"
      :loading="isNextButtonLoading"
      placement="bottom"
      :with-swap="swapStore.hasOffer"
      @next="nextStep"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs, useRoute, useRouter, useRuntimeConfig } from '#imports'
import { computed, onMounted, ref, watch } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api'
import {
  deleteSwap,
  postUpdateOption,
  postUpdateQuantity,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import type {
  CartItem,
  ShippingChoice,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useAuthStore } from '@backmarket/nuxt-layer-oauth/useAuthStore'
import RecommendationCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationCarousel.vue'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useDebounceFn } from '@backmarket/utils/composables/useDebouncedFn'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevContentBlock } from '@ds/components/ContentBlock'
import { RevDivider } from '@ds/components/Divider'
import { openModal } from '@ds/components/ModalBase'
import { IconLockLocked } from '@ds/icons/IconLockLocked'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import type { Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import { MODAL_NAMES as BUYBACK_MODAL_NAMES } from '~/scopes/buyback/constants'
import { SWAP_SUCCESS } from '~/scopes/buyback/swap/components/SwapBlock/SwapBlock.constants'
import SwapBlock from '~/scopes/buyback/swap/components/SwapBlock/SwapBlock.vue'
import SwapSummary from '~/scopes/buyback/swap/components/SwapSummary/SwapSummary.vue'
import { DEBOUNCE_DELAY, MODAL_NAMES } from '~/scopes/checkout/config/constants'
import { HOME } from '~/scopes/home/route-names'
import ReassuranceItems from '~/scopes/reassurance/components/ReassuranceItems/ReassuranceItems.vue'

import BouyguesReassuranceItems from '../../components/BouyguesReassuranceItems/BouyguesReassuranceItems.vue'
import CheckoutNext from '../../components/CheckoutNext.vue'
import ShippingOptions from '../../components/ShippingOptions.vue'
import useHandleUnauthorizedUser from '../../composables/useHandleUnauthorizedUser'
import { CHECKOUT } from '../../routes-names'
import { useCartStore } from '../../stores/cartStore'
import { useLoaderStore } from '../../stores/loaderStore'
import { useSwapStore } from '../../stores/swapStore'

import translations from './Cart.translations'
import CartItemCard from './components/CartItemCard/CartItemCard.vue'
import {
  hasInsuranceSelected,
  hasOfferCompliancyError,
} from './utils/insuranceOffers'

const { openErrorToast } = useTheToast()

const experiments = useExperiments()
const authStore = useAuthStore()
const cartStore = useCartStore()
const loaderStore = useLoaderStore()
const swapStore = useSwapStore()
const runtimeConfig = useRuntimeConfig()
const tracking = useTracking()
const router = useRouter()

const i18n = useI18n()
const route = useRoute()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const {
  market: { countryCode },
} = useMarketplace()

const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

const { availableItems } = storeToRefs(cartStore)

const desiredQuantityPerItem = ref(
  availableItems.value.reduce<Record<string, number>>(
    (acc, { listingId, quantity }) => ({
      ...acc,
      [listingId]: quantity,
    }),
    {},
  ),
)

const shouldDisplayCompliancyError = ref(false)

const firstItemWithUnselectedInsurance = computed(() =>
  cartStore.items.find(
    ({ insuranceOffers }) => !hasInsuranceSelected(insuranceOffers),
  ),
)

const isCrossSellVisible = ref(false)
const hasCollectionPointError = ref(false)

const hasNoItems = computed(() => isEmpty(cartStore.items))
const hasItems = computed(() => !isEmpty(cartStore.items))

const hasDeliveryPromise = computed(
  () =>
    experiments['experiment.deliveryMethodPosition'] ===
    'shippingDeliveryMethod',
)

const shouldShowNewCatchUpModal = computed(
  () => experiments['experiment.catchUpModalRevamp'] === 'newCatchUpModal',
)

const isNextButtonLoading = computed(
  () =>
    !availableItems.value.every(
      (item) => desiredQuantityPerItem.value[item.listingId] === item.quantity,
    ),
)
const isNextButtonDisabled = computed(
  () =>
    !cartStore.hasAvailableItems ||
    cartStore.hasUnavailableItems ||
    isNextButtonLoading.value,
)

const swapAdvertisement = computed(() => ({
  model:
    availableItems.value.length === 1
      ? availableItems.value[0].model
      : i18n(translations.swapMultipleItemsTitle),
  price: i18n.price(swapStore.advertisement?.cartPriceWithSwapDiscount ?? ''),
  hasOffer: swapStore.hasAdvertisement,
}))

const recommendationIds = computed(() =>
  cartStore.items.reduce((acc, item) => `${acc},${item.listingId}`, ''),
)

function trackFunnel() {
  if (typeof route.name === 'string') {
    tracking.trackFunnel(cartStore.trackingData(route.name))
  }
}

onMounted(async () => {
  trackFunnel()
})

const nextStep = async () => {
  tracking.trackClick({
    name: 'go_checkout',
    zone: 'cart',
  })

  // Check if collection point option has a selected collection point
  if (
    !hasDeliveryPromise.value &&
    cartStore.isSelectedCollectionPointMissing &&
    cartStore.optionWithMissingCollectionPointSelected
  ) {
    hasCollectionPointError.value = true
    const firstOption = cartStore.optionWithMissingCollectionPointSelected

    const element = document.getElementById(
      `cart-shipping-option-${firstOption[0]}-${firstOption[1][0]?.choice?.shippingId}`,
    )
    element?.scrollIntoView({ block: 'center', behavior: 'smooth' })

    return
  }

  const firstItemWithCompliancyError = cartStore.items.find(
    ({ insuranceOffers }) => insuranceOffers.some(hasOfferCompliancyError),
  )

  if (firstItemWithCompliancyError) {
    shouldDisplayCompliancyError.value = true

    // scroll to the first product with an error
    const element = document.getElementById(
      `compliancy-${firstItemWithCompliancyError.listingId}`,
    )
    element?.scrollIntoView({ block: 'start', behavior: 'smooth' })

    // block nextStep if compliancy is not checked
    return
  }

  // Catch Up Modal
  const itemsWithSelectableInsurances = cartStore.items.filter(
    ({ insuranceOffers }) => !hasInsuranceSelected(insuranceOffers),
  )

  const hasCartOnlyOneInsuranceSelectable =
    itemsWithSelectableInsurances.length === 1

  const isCatchUpModalEnabled =
    !runtimeConfig.public.KILL_INSURANCE_CATCHUP_MODAL &&
    cartStore.showCatchupModal &&
    countryCode !== MarketCountryCode.JP

  if (hasCartOnlyOneInsuranceSelectable && isCatchUpModalEnabled) {
    // New Catch Up Modal
    if (shouldShowNewCatchUpModal.value)
      openModal(MODAL_NAMES.CATCH_UP_INSURANCE_OFFER)
    // Legacy Catch Up Modal: to be removed (B2CS-638)
    else openModal(MODAL_NAMES.LEGACY_CART_INSURANCE_OFFER_CATCHUP)

    // block nextStep if modal is opened
    return
  }

  try {
    if (authStore.authenticated) {
      // check auth state and proceed
      loaderStore.enable()
      await cartStore.fetchShippings()

      router.push({
        name: cartStore.isShippable
          ? CHECKOUT.ADDRESS_CONFIRMATION
          : CHECKOUT.SHIPPING_ADDRESS,
      })
    } else {
      router.push({
        name: CHECKOUT.AUTHENTICATION,
      })
    }
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[Checkout] Unhandled error going to the next step',
    )
  } finally {
    loaderStore.disable()
  }
}

const updateItem = async (item: CartItem, action: 'delete' | 'update') => {
  try {
    await $httpFetch(postUpdateQuantity, {
      body: {
        action,
        listingId: item.listingId,
        newQuantity: item.quantity,
        listingPrice: item.price,
      },
    })
    if (action === 'delete') {
      const product = cartStore
        .trackingData(route.name as string)
        .products.find(
          (trackingProduct) => item.productId === trackingProduct.id,
        )

      if (product) {
        tracking.trackRemoveFromCart({
          product,
        })
      }
    }

    await cartStore.fetchCart()

    trackFunnel()
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating quantity',
    )
  }
}

const handleDeleteButtonClick = async (item: CartItem) => {
  await updateItem(item, 'delete')
  delete desiredQuantityPerItem.value[item.listingId]
}

const handleQuantitySelectorChange = useDebounceFn(
  async function doUpdateQuantity(item: CartItem, quantity: number) {
    desiredQuantityPerItem.value[item.listingId] = quantity
    await updateItem({ ...item, quantity }, 'update')
  },
  DEBOUNCE_DELAY,
)

//  This method udpate items in the cart without fetching the cart again
async function handleUpdateCartItem(udpatedItem: CartItem) {
  try {
    const udpatedItems = cartStore.items.reduce<CartItem[]>((items, item) => {
      if (item.listingId === udpatedItem.listingId) {
        return [...items, udpatedItem]
      }

      return [...items, item]
    }, [])

    cartStore.setItems(udpatedItems)

    trackFunnel()
  } catch {
    openErrorToast()
  } finally {
    await cartStore.fetchCart()
  }
}

const selectOption = async (
  body: {
    listingId: string
    optionType: string
    optionId: string
  },
  choice: ShippingChoice,
) => {
  hasCollectionPointError.value = false

  try {
    await $httpFetch(postUpdateOption, {
      body,
    })

    tracking.trackClick({
      zone: 'cart',
      name: choice.shipperDisplay || 'not-available',
    })

    await cartStore.fetchCart()
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating shipping option',
    )
  }
}

const handleSwapConfirmation = async () => {
  await cartStore.fetchCart()
}

const handleDeleteSwap = async () => {
  try {
    await $httpFetch(deleteSwap)
    const eventName = 'delete_swap'

    tracking.trackSwap({
      label: eventName,
      action: 'funnel > Step 4',
      ...(swapStore.offer && swapStore.offer.diagnosticPayload),
    })

    tracking.trackClick({
      name: eventName,
      value: { ...(swapStore.offer && swapStore.offer.diagnosticPayload) },
      zone: 'swap',
    })

    await cartStore.fetchCart()
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error deleting swap',
    )
  }
}

const handleCrossSellLoaded = (results: Product[]) => {
  if (isEmpty(results)) {
    isCrossSellVisible.value = false
  } else {
    isCrossSellVisible.value = true
  }
}

const handleCrossSellRefresh = async () => {
  await cartStore.fetchCart()
}

watch(
  () => availableItems.value,
  (newItems) => {
    desiredQuantityPerItem.value = newItems.reduce<Record<string, number>>(
      (acc, { listingId, quantity }) => ({
        ...acc,
        [listingId]: quantity,
      }),
      {},
    )
  },
)
</script>
